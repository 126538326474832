@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: "ProximaNovaRegular", -apple-system, BlinkMacSystemFont,
		"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/*
Font Weight	Numerical Weight
"Thin", "Hairline"	100
"Extra Light", "Ultra Light"	200
"Light", "Book"	300
"Normal", "Regular", "Roman", "Standard", "Plain"	400/Normal
"Medium", "Demi"	500
"Semi Bold", "Semi Bld", "Demi Bold", "Demi Bld"	600
"Bold", "Bld"	700/Bold
"Extra Bold", "Ultra Bold", "Extra Bld", "Ultra Bld"	800
"Black", "Heavy", "Ultra", "Fat", "Poster", "Ultra Black" 	 900
*/

@layer base {
	@font-face {
		font-family: "ProximaNovaThin";
		font-weight: 100;
		src: local("ProximaNovaThin"),
			url(./assets/fonts/proximanova-thin.otf) format("opentype");
	}
	@font-face {
		font-family: "ProximaNovaLight";
		font-weight: 300;
		src: local("ProximaNovaLight"),
			url(./assets/fonts/proximanova-light.otf) format("opentype");
	}
	@font-face {
		font-family: "ProximaNovaRegular";
		font-weight: 400;
		src: local("ProximaNovaRegular"),
			url(./assets/fonts/proximanova-regular.otf) format("opentype");
	}
	@font-face {
		font-family: "ProximaNovaMedium";
		font-weight: 500;
		src: local("ProximaNovaMedium"),
			url(./assets/fonts/proximanova-medium.otf) format("opentype");
	}
	@font-face {
		font-family: "ProximaNovaSemiBold";
		font-weight: 600;
		src: local("ProximaNovaSemiBold"),
			url(./assets/fonts/proximanova-semibold.otf) format("opentype");
	}
	@font-face {
		font-family: "ProximaNovaBold";
		font-weight: 700;
		src: local("ProximaNovaBold"),
			url(./assets/fonts/proximanova-bold.otf) format("opentype");
	}
	@font-face {
		font-family: "ProximaNovaExtraBold";
		font-weight: 800;
		src: local("ProximaNovaExtraBold"),
			url(./assets/fonts/proximanova-extrabold.otf) format("opentype");
	}
	@font-face {
		font-family: "ProximaNovaBlack";
		font-weight: 900;
		src: local("ProximaNovaBlack"),
			url(./assets/fonts/proximanova-black.otf) format("opentype");
	}
}

.mySVG-button:hover .mySVG-icon-black {
	fill: white !important;
}
.mySVG-button:hover .mySVG-icon-white {
	fill: black !important;
}

.scrolling-text {
	width: 100%; /* set the width of the container */
	/* overflow: hidden; hide the overflow of the container */
}

.scrolling-text span {
	display: inline-block; /* make the spans appear in one line */
	animation: scroll 5s linear infinite; /* set the animation properties */
}

@keyframes scroll {
	0% {
		transform: translateX(0); /* start at 0 position */
	}
	50% {
		transform: translateX(-100%); /* move to the left */
	}
	51% {
		transform: translateX(
			100%
		); /* immediately appear after the first text disappears */
	}
	100% {
		transform: translateX(0); /* move back to the original position */
	}
}

.scrollingText span {
	display: inline-block;
	width: max-content;
	/* show the scrollingText just outside the paragraph */
	will-change: transform;
	animation: scrollingText 10s linear infinite;
}
.scrollingText span:hover {
	animation-play-state: paused;
}
@keyframes scrollingText {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(-100%, 0);
	}
}
/* Respect user preferences about animations */
@media (prefers-reduced-motion: reduce) {
	.scrollingText span {
		animation-iteration-count: 1;
		animation-duration: 0.01;
		/* instead of animation: none, so an animationend event is still available, if previously attached.*/
		width: auto;
		padding-left: 0;
	}
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}
@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
